import { FC, Fragment, useState } from "react";
import { PageProps } from "gatsby";
import { Router, Redirect } from "@reach/router";

import ReferralComponent from "src/components/ReferralComponent";
import Meta from "src/components/Meta";

const Referral: FC<PageProps> = ({ uri }) => {
  const [isReferralValid, setIsReferralValid] = useState(true);
  return (
    <Fragment>
      <Meta
        title={
          isReferralValid
            ? "Profitez d’un mois offert supplémentaire | Shine"
            : "Offre de parrainage non disponible | Shine"
        }
        metadescription="Découvrez Shine, le compte pro et copilote administratif des freelances et petites entreprises."
        indexed={false}
      />
      <Router basepath={uri}>
        <ReferralComponent
          path="/:code"
          onReferral={(result) => setIsReferralValid(result)}
        />
        <Redirect from="/" to="/404" noThrow />
      </Router>
    </Fragment>
  );
};

export default Referral;
