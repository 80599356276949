import { useRef, useEffect, useState } from "react";

import useVisitorContext from "src/providers/VisitorContext/useVisitorContext";

const useReferral = (code: string) => {
  const { updateVisitorContext } = useVisitorContext();
  const [referral, setReferral] = useState<{
    referralCode: string | null;
    referrerName: string | null;
    error: boolean;
    loading?: boolean;
  }>({
    referralCode: null,
    referrerName: null,
    error: false,
    loading: true,
  });
  const isMounted = useRef(false);

  useEffect(() => {
    isMounted.current = true;

    return () => {
      isMounted.current = false;
    };
  });

  useEffect(() => {
    const errorState = {
      referralCode: null,
      referrerName: null,
      error: true,
      loading: false,
    };

    if (!process.env.GATSBY_PUBLIC_GRAPHQL_HOST) {
      setReferral(errorState);

      return;
    }

    const VerifyReferralCodeMutation = `mutation VerifyReferralCode($input: VerifyReferralCodeInput!) {
      verifyReferralCode(input: $input) {
        firstName
      }
    }
    `;
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        query: VerifyReferralCodeMutation,
        variables: {
          input: {
            referralCode: code,
          },
        },
      }),
    };

    fetch(process.env.GATSBY_PUBLIC_GRAPHQL_HOST, options)
      .then(async (response) => {
        if (!isMounted.current) {
          return;
        }

        if (response.status !== 200) {
          setReferral(errorState);

          return;
        }

        try {
          const { data, errors } = await response.json();

          if (errors?.length > 0 || !data?.verifyReferralCode?.firstName) {
            setReferral(errorState);
            return;
          }

          setReferral({
            referralCode: code,
            referrerName: data.verifyReferralCode.firstName,
            error: false,
            loading: false,
          });

          updateVisitorContext({
            referrerCode: code,
            referrerName: data.verifyReferralCode.firstName,
          });
        } catch (error) {
          setReferral(errorState);
        }
      })
      .catch(() => setReferral(errorState));
  }, [code, updateVisitorContext]);

  return referral;
};

export default useReferral;
