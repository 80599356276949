/**
 * A function posting the new referral on the Shine API endpoint.
 */
export const createReferral = async (code: string, phone: string) => {
  if (!process.env.GATSBY_PUBLIC_GRAPHQL_HOST) {
    return;
  }

  const CreateReferralMutation = `mutation CreateReferral($input: CreateReferralInput!) {
    createReferral(input: $input) {
      referralId,
    }
  }
  `;
  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      query: CreateReferralMutation,
      variables: {
        input: {
          referralCode: code,
          referreePhone: phone,
        },
      },
    }),
  };

  const response = await fetch(process.env.GATSBY_PUBLIC_GRAPHQL_HOST, options);
  if (!response.ok) {
    throw new Error("Error creating referral");
  }

  const { data, errors } = await response.json();

  if (errors?.length > 0) {
    throw new Error("Error creating referral");
  }

  return data;
};
